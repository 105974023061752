.threeDTourButton {
  color: var(--color-blue-600);
  background: var(--color-white);
  border-radius: var(--border-radius-5x);
  font-size: var(--font-size-sm);
  padding: var(--space-1x) var(--space-2-5x);
  line-height: var(--leading-normal);
  z-index: var(--z-index-2x);
  font-weight: var(--font-weight-semibold);
  transition: all 100ms cubic-bezier(0, 0, 0.2, 1);
  transition-property: color, background-color;

  @media (--supports-hover) {
    &:hover {
      background-color: var(--color-gray-200);
      color: var(--color-black);
    }

    &:active {
      background-color: var(--color-gray-300);
      color: var(--color-black);
    }
  }
}
