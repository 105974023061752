@layer components {
  .iconCtaStyle {
    border-radius: var(--icon-cta-border-radius);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: var(--icon-cta-margin);
    padding: var(--icon-cta-padding);
    position: var(--icon-cta-position);
    background-color: var(--icon-cta-background-color);
    font-size: var(--icon-cta-font-size);
    width: var(--icon-cta-width);
    height: var(--icon-cta-height);
    z-index: var(--icon-cta-z-index);
    top: var(--icon-cta-top);
    right: var(--icon-cta-right);
    box-shadow: var(--icon-cta-box-shadow);
  }

  .filledHeart {
    color: var(--button-background-color-base);
  }

  .clickAnimation {
    animation: 400ms linear 0s 1 pulse-heart;
  }

  .buttonContainer {
    display: flex;
  }

  .shareButtonAG {
    font-size: var(--font-size-md);
  }

  .favoriteButtonAG {
    font-size: var(--font-size-body);
  }

  .favoriteLabelText {
    font-weight: var(--font-weight-semibold);
    line-height: 1;
    font-size: var(--font-size-body);
  }

  .withRentStyles .favoriteLabelText {
    font-size: var(--font-size-sm);
  }

  .shareButtonAG,
  .favoriteButtonAG {
    height: 34px;
    background-color: var(--color-gray-100);
    border-radius: var(--border-radius-6x);
    gap: var(--space-1x);
    font-weight: var(--font-semibold);
    --icon-cta-base-color: var(--color-blue-600);
    --icon-cta-padding: var(--space-none) var(--space-2-5x);
    --icon-cta-width: auto;

    @media (--supports-hover) {
      &:hover {
        background-color: var(--color-gray-200);
        --icon-cta-hover-color: var(--color-blue-700);
      }

      &:active {
        background-color: var(--color-gray-300);
        --icon-cta-active-color: var(--color-blue-800);
      }
    }
  }

  .withRentStyles {
    --button-height: 26px;
    --button-width: var(--space-10x);
    --button-padding: var(--space-1-5x) var(--space-2x);
    position: relative;
    display: flex;
    align-items: center;

    &::after {
      content: '';
      position: absolute;
      top: -10px;
      bottom: -10px;
      left: 0;
      right: 0;
    }
  }

  .savedText {
    font-size: var(--font-size-sm);
  }

  @keyframes pulse-heart {
    0% {
      transform: scale(1.1);
    }
    20% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  .favoriteButton {
    display: flex;
    --icon-cta-filled-color: var(--bookmark-filled-background-color);
  }

  .favoriteButton.withRentStyles {
    --button-width: 70px;
    --button-gap: 4px;

    .filledHeart {
      color: var(--color-pink-400);
    }
  }

  .default {
    color: var(--icon-cta-base-color);

    &:hover {
      color: var(--icon-cta-hover-color);
      background-color: var(--icon-cta-hover-background-color);
    }

    &:active {
      color: var(--icon-cta-active-color);
      background-color: var(--icon-cta-active-background-color);
    }
  }

  .filled {
    color: var(--icon-cta-filled-color);
  }
}
